<template>
    <div class="error-info full-width">
        <div class="error-content">
            <h1>403</h1>
            <h3 class="font-bold">暂无权限</h3>

            <div class="error-desc">
                您暂无此项目权限...
                <br/>
                申请访问权限，请联系管理员
                <br/>您可以退出切换有权限的账号
            </div>
        </div>              
    </div>
</template>

<script type="text/javascript">
    export default {
        name : "error-forbidden"
    };
</script>

<style lang="scss" scoped="">
    .error-info{
        display:flex;
        height : 100%;
        justify-content: center;
        align-items:center;
    }

    body{
        background-color : #f3f3f4;
    }
    .exit{
        cursor : pointer;
        color : blue;
    }
</style>